import React from 'react';

import { DatePicker, Input } from '@Dogtainers/dgt-blocks/src/components/form';
import { Grid } from '@material-ui/core';

import { MediaTypeProps } from '../types';

const Print: React.FC<MediaTypeProps> = ({
  handleChange,
  values,
  errors: { mediaEnquiry: mediaEnquiryError },
}) => {
  const { print } = values || {};
  const { print: printErrors } = mediaEnquiryError || {};

  return (
    <>
      <Grid item>
        <DatePicker
          label="Print publication date"
          name="mediaEnquiry.print.printPublicationDate"
          onChange={handleChange}
          value={print?.printPublicationDate}
          error={printErrors?.printPublicationDate}
        />
      </Grid>
      <Grid item>
        <Input
          label="Publication topic"
          name="mediaEnquiry.print.publicationTopic"
          placeholder="e.g. Pet Travel"
          onChange={handleChange}
          value={print?.publicationTopic}
          error={printErrors?.publicationTopic}
        />
      </Grid>
      <Grid item>
        <Input
          multiline
          rows={6}
          label="Publication brief"
          placeholder="Give us a quick rundown of the project and any info you think we may need."
          name="mediaEnquiry.print.publicationBrief"
          onChange={handleChange}
          value={print?.publicationBrief}
          error={printErrors?.publicationBrief}
        />
      </Grid>
    </>
  );
};

export default Print;
