import React, { useMemo } from 'react';

import { Input } from '@Dogtainers/dgt-blocks/src/components/form';
import { Grid } from '@material-ui/core';

import Dropzone from '../../../../components/Dropzone/dropzone';
import { fetchAwsFilesParamsContacts } from '../../../../components/Dropzone/dropzone.utils';
import { FileValue } from '../../../booking/booking.types';
import { ContactFormPartial } from '../../contact.types';

type JobApplicationEnquiryProps = {
  values: ContactFormPartial;
  recaptchaToken: string | null;
  handleChange: (event: React.ChangeEvent<HTMLElement>) => void;
  setFieldValue?: (fieldName: string, fieldValue: FileValue[]) => void;
  errors: ContactFormPartial;
  submitSection: JSX.Element;
};

const JobApplicationEnquiry: React.FC<JobApplicationEnquiryProps> = (props) => {
  const {
    values: { jobApplicationEnquiry, nameFirst, nameLast, email, phone },
    handleChange,
    setFieldValue,
    submitSection,
    errors: {
      jobApplicationEnquiry: jobApplicationEnquiryErrors,
      nameFirst: nameFirstErrors,
      nameLast: nameLastErrors,
      email: emailErrors,
      phone: phoneErrors,
    },
  } = props;

  const showEnquiry = useMemo(() => {
    // Check if all required fields have values
    const allFieldsFilled = nameFirst && nameLast && email && phone;

    // Check if any errors exist for the required fields
    const anyErrorsExist =
      nameFirstErrors || nameLastErrors || emailErrors || phoneErrors;

    // Return true if all required fields have values and no errors exist
    return allFieldsFilled && !anyErrorsExist;
  }, [
    nameFirst,
    nameLast,
    email,
    phone,
    nameFirstErrors,
    nameLastErrors,
    emailErrors,
    phoneErrors,
  ]);

  if (!showEnquiry) return <></>;

  return (
    <>
      <Grid item>
        <Input
          label="What position are you interested in?"
          placeholder="Add your response here"
          name="jobApplicationEnquiry.role"
          onChange={handleChange}
          value={jobApplicationEnquiry?.role}
          error={jobApplicationEnquiryErrors?.role}
        />
      </Grid>
      <Grid item>
        <Dropzone
          key="resume"
          label="Drag and drop your resume here or click to upload"
          text="Upload a resume"
          acceptTypeText="pdf, doc, docx"
          name="jobApplicationEnquiry.resume"
          maxFiles={1}
          maxSize={4000000}
          accept={{
            'application/pdf': [],
            'application/doc': [],
            'application/docx': [],
          }}
          setFieldValue={setFieldValue}
          value={jobApplicationEnquiry?.resume as FileValue[]}
          error={String(jobApplicationEnquiryErrors?.resume) ?? ''}
          required
          fetchAwsFiles={(newFiles) => fetchAwsFilesParamsContacts(newFiles)}
        />
      </Grid>
      <Grid item>
        <Dropzone
          key="cover-letter"
          label="Drag and drop your cover letter here or click to upload"
          text="Upload a cover letter"
          acceptTypeText="pdf, doc, docx"
          name="jobApplicationEnquiry.coverLetter"
          maxFiles={1}
          maxSize={4000000}
          accept={{
            'application/pdf': [],
            'application/doc': [],
            'application/docx': [],
          }}
          setFieldValue={setFieldValue}
          value={jobApplicationEnquiry?.coverLetter as FileValue[]}
          fetchAwsFiles={(newFiles) => fetchAwsFilesParamsContacts(newFiles)}
        />
      </Grid>
      <Grid item>
        <Input
          multiline
          rows={6}
          label="Do you want to tell us anything else?"
          placeholder="Add your response here"
          name="jobApplicationEnquiry.about"
          onChange={handleChange}
          value={jobApplicationEnquiry?.about}
          required={false}
        />
      </Grid>
      {submitSection}
    </>
  );
};

export default JobApplicationEnquiry;
