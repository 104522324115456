import { useFormik } from 'formik';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';

import {
  Autocomplete,
  AutocompleteItem,
} from '@Dogtainers/dgt-blocks/src/components/form/autocomplete';
import { useIsMobile } from '@Dogtainers/dgt-blocks/src/hooks/useBreakpoints';
import { Grid } from '@material-ui/core';

import { localeCountryId } from '../../../../utils/language';
import { handleAutoFocus, handleFocusField, isGBLocale } from '../../../utils';
import { usePickupAndDeliveryAddress } from '../../hooks/usePickupAndDeliveryAddress';
import { FormDataQuery, PageFormProps } from '../../quote.form';
import { Airport } from '../../quote.types';
import { renderTooltipForAU } from '../../utils';

type DestinationFieldsType = Pick<
  PageFormProps,
  | 'values'
  | 'handleChange'
  | 'errors'
  | 'touched'
  | 'handleBlur'
  | 'isErrorsVisible'
  | 'isFieldInFocus'
> & { setFieldValue: ReturnType<typeof useFormik>['setFieldValue'] };

const mainPortsAU = [
  'Adelaide',
  'Brisbane',
  'Canberra',
  'Cairns',
  'Darwin',
  'Hobart',
  'Melbourne',
  'Perth',
  'Sydney',
];

const sortAirportsByAlphabet = (airports: Airport[]): Airport[] =>
  airports.sort((a, b) => a.label.localeCompare(b.label));

const DestinationFields: React.FC<DestinationFieldsType> = ({
  values,
  handleChange,
  setFieldValue,
  errors,
  touched,
  handleBlur,
  isErrorsVisible,
  isFieldInFocus,
}) => {
  const query = graphql`
    query QuotePage2 {
      allCountriesJson {
        nodes {
          id
          label
        }
      }
    }
  `;
  const staticData = useStaticQuery<FormDataQuery>(query);
  const countries: AutocompleteItem[] = staticData.allCountriesJson.nodes.map(
    ({ id, label }) => ({ value: id, label }),
  );
  const [allAirports, setAllAirports] = useState<Airport[]>([]);
  const isMobile = useIsMobile();
  const {
    arrival,
    departure,
    pickupAddress,
    deliveryAddress,
    pickupAddresses,
    deliveryAddresses,
    handleInputMapChange,
    handleAddressChange,
    generateAutocompleteItems,
  } = usePickupAndDeliveryAddress({
    values,
    handleChange,
    setFieldValue,
  });

  const getAirports = useCallback(
    (countryId?: string) => {
      if (!allAirports.length) {
        return [];
      }

      let countryAirports = countryId
        ? allAirports.filter((a) => a.countryCode === countryId)
        : allAirports;

      if (localeCountryId === 'AU' && values.quoteType === 'domestic') {
        const mainAuAirports = countryAirports.filter((a) =>
          mainPortsAU.includes(a.label),
        );
        const otherAuAirports = countryAirports.filter(
          (a) => !mainPortsAU.includes(a.label),
        );

        countryAirports = [
          ...mainAuAirports,
          ...sortAirportsByAlphabet(otherAuAirports),
        ];
      } else {
        countryAirports = sortAirportsByAlphabet(countryAirports);
      }

      return countryAirports
        .map((a) => ({ value: a.id, label: a.label }))
        .concat([{ value: '', label: '' }]);
    },
    [allAirports],
  );

  const departureAirports: AutocompleteItem[] = getAirports(
    values.quoteType === 'domestic'
      ? localeCountryId
      : values.departure?.countryId,
  );
  const arrivalAirports: AutocompleteItem[] = getAirports(
    values.quoteType === 'domestic'
      ? localeCountryId
      : values.arrival?.countryId,
  );

  // Load all airports from local static file
  useEffect(() => {
    fetch('/data/airports.json').then((response) => {
      response.json().then((body: Airport[]) => {
        setAllAirports(body);
      });
    });
  }, []);

  return (
    <>
      {values.quoteType === 'international' && (
        <Grid item xs={12} sm={6}>
          <Autocomplete
            name="departure.countryId"
            label="Departure country"
            menuItems={countries}
            error={errors?.departure?.countryId}
            value={departure?.countryId}
            onChange={handleChange}
            touched={touched.departure?.countryId}
            isErrorVisible={isErrorsVisible}
            onBlur={handleBlur}
            innerRef={isFieldInFocus?.('departure.countryId')}
            onFocus={({ target }) => handleFocusField(isMobile, target)}
          />
        </Grid>
      )}

      {isGBLocale && values.quoteType === 'domestic' ? (
        <Grid item xs={12} sm={6}>
          <Autocomplete
            menuItems={generateAutocompleteItems(pickupAddresses)}
            placeholder="Enter address"
            label="Pickup address"
            name="departure.pickupAddress.address"
            onInputChange={(value: string) =>
              handleInputMapChange(value, 'pickup')
            }
            onChange={(e) => handleAddressChange(e, 'departure.pickupAddress')}
            value={pickupAddress?.address || ''}
            error={errors?.departure?.pickupAddress?.address}
            controlledState
            bypassFilterOptions
            touched={touched.departure?.pickupAddress}
            isErrorVisible={isErrorsVisible}
            onBlur={handleBlur}
            innerRef={isFieldInFocus?.('departure.pickupAddress.address')}
            onFocus={({ target }) => handleFocusField(isMobile, target)}
          />
        </Grid>
      ) : (
        <Grid item xs={12} sm={6}>
          <Autocomplete
            name="departure.airportId"
            label="Departure airport"
            tooltip={renderTooltipForAU(
              "Even if air travel is not your plan, please provide the nearest airport to both your origin and destination. There's a section at the end of the quote request form where you can include any additional notes or information.",
            )}
            menuItems={departureAirports}
            error={errors?.departure?.airportId}
            value={departure?.airportId}
            onChange={handleChange}
            key={`${!errors?.departure?.countryId}_departure.airportId`}
            autoFocus={handleAutoFocus(
              isMobile,
              !departure?.countryId || errors?.departure?.countryId,
              departure?.airportId,
            )}
            touched={touched.departure?.airportId}
            isErrorVisible={isErrorsVisible}
            onBlur={handleBlur}
            innerRef={isFieldInFocus?.('departure.airportId')}
            onFocus={({ target }) => handleFocusField(isMobile, target)}
          />
        </Grid>
      )}

      {values.quoteType === 'international' && (
        <Grid item xs={12} sm={6}>
          <Autocomplete
            name="arrival.countryId"
            label="Arrival country"
            menuItems={countries}
            error={errors?.arrival?.countryId}
            value={arrival?.countryId}
            onChange={handleChange}
            touched={touched.arrival?.countryId}
            isErrorVisible={isErrorsVisible}
            onBlur={handleBlur}
            key={`${!errors?.departure?.airportId}_arrival.countryId`}
            autoFocus={handleAutoFocus(
              isMobile,
              !departure?.airportId || errors?.departure?.airportId,
              arrival?.countryId,
            )}
            innerRef={isFieldInFocus?.('arrival.countryId')}
            onFocus={({ target }) => handleFocusField(isMobile, target)}
          />
        </Grid>
      )}

      {isGBLocale && values.quoteType === 'domestic' ? (
        <Grid item xs={12} sm={6}>
          <Autocomplete
            menuItems={generateAutocompleteItems(deliveryAddresses)}
            placeholder="Enter address"
            label="Delivery address"
            name="arrival.deliveryAddress.address"
            onInputChange={(value: string) =>
              handleInputMapChange(value, 'delivery')
            }
            onChange={(e) => handleAddressChange(e, 'arrival.deliveryAddress')}
            value={deliveryAddress?.address || ''}
            error={errors?.arrival?.deliveryAddress?.address}
            controlledState
            bypassFilterOptions
            touched={touched.arrival?.deliveryAddress}
            isErrorVisible={isErrorsVisible}
            onBlur={handleBlur}
            innerRef={isFieldInFocus?.('arrival.deliveryAddress.address')}
            key={`${!errors?.arrival?.deliveryAddress
              ?.address}_arrival.deliveryAddress.address`}
            autoFocus={handleAutoFocus(
              isMobile,
              errors?.arrival?.deliveryAddress?.address,
              deliveryAddress?.address,
            )}
            onFocus={({ target }) => handleFocusField(isMobile, target)}
          />
        </Grid>
      ) : (
        <Grid item xs={12} sm={6}>
          <Autocomplete
            name="arrival.airportId"
            label="Arrival airport"
            tooltip={renderTooltipForAU(
              "Even if air travel is not your plan, please provide the nearest airport to both your origin and destination. There's a section at the end of the quote request form where you can include any additional notes or information.",
            )}
            menuItems={arrivalAirports}
            error={errors?.arrival?.airportId}
            value={arrival?.airportId}
            onChange={handleChange}
            touched={touched.arrival?.airportId}
            isErrorVisible={isErrorsVisible}
            onBlur={handleBlur}
            key={`${
              values.quoteType === 'international'
                ? !errors?.arrival?.countryId
                : !errors?.departure?.airportId
            }_arrival.airportId`}
            autoFocus={handleAutoFocus(
              isMobile,
              values.quoteType === 'international'
                ? !arrival?.countryId || errors?.arrival?.countryId
                : !departure?.airportId || errors?.departure?.airportId,
              arrival?.airportId,
            )}
            innerRef={isFieldInFocus?.('arrival.airportId')}
            onFocus={({ target }) => handleFocusField(isMobile, target)}
          />
        </Grid>
      )}
    </>
  );
};

export default DestinationFields;
