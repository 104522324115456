import { navigate } from 'gatsby';
import React, { useCallback } from 'react';

import { Image } from '@Dogtainers/dgt-blocks/src/components';
import { Box, Link, Typography, makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useStore } from '../../../../components/store';
import { useImages } from '../../../../hooks/useImages';
import { useQuoteAirports } from '../../../../hooks/useQuoteAirpotrs';
import { localiseUrl } from '../../../../utils/language';
import { useQuoteConfiguration } from '../../hooks/useQuoteConfiguration';

const useStyles = makeStyles((theme) => ({
  image: {
    width: 198,
    height: 136,
    [theme.breakpoints.down('xs')]: {
      width: '166px',
    },
  },
  summariesContainer: {
    marginBottom: 50,
  },
  summaries: {
    padding: '30px 0',
    borderTop: `1px solid ${theme.palette.black.light}`,
    borderBottom: `1px solid ${theme.palette.black.light}`,
    '& .pet-cost': {
      marginBottom: 16,
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  btnEditBooking: {
    cursor: 'pointer',
    textTransform: 'uppercase',
  },
}));

type PetSummaryType = {
  title: string;
  isEditable?: boolean;
  showPrice?: boolean;
};

const PetSummary: React.FC<PetSummaryType> = ({
  title,
  isEditable,
  showPrice,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { getImageData } = useImages();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  const { depatureAirport, arrivalAirport } = useQuoteAirports();
  const bookingQuote = useStore((state) => state.bookingQuote);
  const { quoteConfiguration } = useQuoteConfiguration();

  const choosePicture = useCallback((typeId: string, weight: number) => {
    const imgSrc = `confirmation-quote/${
      typeId === 'cat'
        ? 'Cat'
        : typeId === 'dog'
        ? weight > 25
          ? 'BigDog'
          : 'SmallDog'
        : 'Other'
    }.png`;

    return (
      <Image
        getImageData={getImageData}
        src={imgSrc}
        alt={typeId}
        options={{
          maxWidth: '100%',
          maxHeight: '100%',
        }}
      />
    );
  }, []);

  return (
    <Box className={classes.summariesContainer}>
      <Box marginBottom="10px">
        <Typography variant="h6">
          <b>{title}</b>
        </Typography>
      </Box>

      <Box className={classes.summaries}>
        {bookingQuote &&
          bookingQuote.pets?.map(
            ({ id, typeId, weight, breedId, totalCost }) => (
              <Box key={id} display="flex" className="pet-cost">
                <Box className={classes.image}>
                  {choosePicture(typeId, weight)}
                </Box>
                <Box
                  ml={matches ? '18px' : 2.5}
                  flex="1"
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                >
                  <Box
                    display="flex"
                    alignItems={matches ? 'flex-start' : 'flex-end'}
                    justifyContent="space-between"
                    flexDirection={matches ? 'column' : 'row'}
                    mb={matches ? 2.5 : 0}
                  >
                    <Box mb={matches ? '18px' : 0}>
                      <Typography>
                        <span style={{ textTransform: 'capitalize' }}>
                          {quoteConfiguration?.quoteType} Pet Transfer
                        </span>
                      </Typography>
                      <Typography variant="body2">
                        {breedId || typeId} • {depatureAirport} to{' '}
                        {arrivalAirport} • {bookingQuote?.departure?.date}{' '}
                      </Typography>
                    </Box>
                    {showPrice && <Typography>${totalCost?.amount}</Typography>}
                  </Box>
                  {isEditable && (
                    <Link
                      onClick={() =>
                        navigate(
                          `${localiseUrl('quote')}?type=${
                            bookingQuote?.quoteType
                          }&prefill=yes`,
                        )
                      }
                      className={classes.btnEditBooking}
                    >
                      Edit Booking
                    </Link>
                  )}
                </Box>
              </Box>
            ),
          )}
      </Box>
    </Box>
  );
};

export default PetSummary;
