import React, { useCallback, useEffect } from 'react';

import { Input, Radio } from '@Dogtainers/dgt-blocks/src/components/form';
import { Grid, Typography } from '@material-ui/core';

import {
  LocationResponse,
  findAddressProperty,
  getGooglePlaceDetails,
} from '../../../utils/googleMap.utils';
import { PageFormProps } from '../booking.types';
import useStyles from './styles';

const radioToggleItems = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export const BookingPage4: React.FC<PageFormProps> = ({
  values: { receiver, arrival },
  errors: { receiver: receiverErrors, arrival: arrivalErrors },
  handleChange,
  isValueEqual,
  setFieldValue,
  handleBlur,
  touched,
  isErrorsVisible,
  isFieldInFocus,
  children,
}) => {
  const { pageTitle, pageSubTitle, subTitleDetails } = useStyles();

  const prefillAddressFields = useCallback(
    (location: LocationResponse) => {
      if (location.address_components) {
        const addressComponents = location.address_components;

        setFieldValue(
          'arrival.deliveryAddress.streetAddress',
          findAddressProperty(addressComponents, 'route'),
        );

        setFieldValue(
          'arrival.deliveryAddress.city',
          findAddressProperty(addressComponents, 'locality') ||
            findAddressProperty(addressComponents, 'postal_town'),
        );

        setFieldValue(
          'arrival.deliveryAddress.state',
          findAddressProperty(addressComponents, 'country'),
        );

        setFieldValue(
          'arrival.deliveryAddress.postcode',
          findAddressProperty(addressComponents, 'postal_code'),
        );
      }
    },
    [setFieldValue],
  );

  useEffect(() => {
    const fetchAddressDetails = async (placeId: string) => {
      const details = await getGooglePlaceDetails(placeId);

      prefillAddressFields(details);
    };

    if (
      arrival?.deliveryAddress?.placeId &&
      !arrival?.deliveryAddress?.streetAddress
    )
      fetchAddressDetails(arrival.deliveryAddress.placeId);
  }, [prefillAddressFields]);

  return (
    <Grid container justifyContent="center" spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h4" className={pageTitle}>
          Booking Form
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" className={pageSubTitle}>
          Reciever Details
          <span className={subTitleDetails}>
            Who will be picking up your pet?
          </span>
        </Typography>
      </Grid>
      {children}
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Input
              label="First Name"
              name="receiver.nameFirst"
              placeholder="Name here"
              onChange={handleChange}
              value={receiver?.nameFirst}
              error={receiverErrors?.nameFirst}
              onBlur={handleBlur}
              touched={touched.receiver?.nameFirst}
              isErrorVisible={isErrorsVisible}
              innerRef={isFieldInFocus?.('receiver.nameFirst')}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              label="Last Name"
              name="receiver.nameLast"
              placeholder="Last name here"
              onChange={handleChange}
              value={receiver?.nameLast}
              error={receiverErrors?.nameLast}
              onBlur={handleBlur}
              touched={touched.receiver?.nameLast}
              isErrorVisible={isErrorsVisible}
              innerRef={isFieldInFocus?.('receiver.nameLast')}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              label="Phone Number"
              name="receiver.phone"
              placeholder="e.g. +61 888 888 888 "
              onChange={handleChange}
              value={receiver?.phone}
              error={receiverErrors?.phone}
              onBlur={handleBlur}
              touched={touched.receiver?.phone}
              isErrorVisible={isErrorsVisible}
              innerRef={isFieldInFocus?.('receiver.phone')}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              label="Email"
              name="receiver.email"
              placeholder="email@contents.com"
              onChange={handleChange}
              value={receiver?.email}
              error={receiverErrors?.email}
              onBlur={handleBlur}
              touched={touched.receiver?.email}
              isErrorVisible={isErrorsVisible}
              innerRef={isFieldInFocus?.('receiver.email')}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Radio
              label="Do you require delivery?"
              name="arrival.isDelivery"
              onChange={handleChange}
              menuItems={radioToggleItems}
              value={arrival?.isDelivery}
              error={String(arrivalErrors?.isDelivery)}
              isErrorVisible={isErrorsVisible}
              innerRef={isFieldInFocus?.('arrival.isDelivery')}
            />
          </Grid>

          {isValueEqual('arrival.isDelivery', true) && (
            <>
              <Grid item xs={12} md={6}>
                <Radio
                  label="Will you be completing home quarantine?"
                  name="arrival.deliveryAddress.isCompletingHomeQuarantine"
                  onChange={handleChange}
                  menuItems={radioToggleItems}
                  value={arrival?.deliveryAddress?.isCompletingHomeQuarantine}
                  error={String(
                    arrivalErrors?.deliveryAddress?.isCompletingHomeQuarantine,
                  )}
                  isErrorVisible={isErrorsVisible}
                  innerRef={isFieldInFocus?.(
                    'arrival.deliveryAddress.isCompletingHomeQuarantine',
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  label="Street Address"
                  name="arrival.deliveryAddress.streetAddress"
                  placeholder="e.g. 1 Name St, Place"
                  onChange={handleChange}
                  value={arrival?.deliveryAddress?.streetAddress}
                  error={arrivalErrors?.deliveryAddress?.streetAddress}
                  onBlur={handleBlur}
                  touched={touched.arrival?.deliveryAddress?.streetAddress}
                  isErrorVisible={isErrorsVisible}
                  innerRef={isFieldInFocus?.(
                    'arrival.deliveryAddress.streetAddress',
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  label="Street Address Line 2"
                  name="arrival.deliveryAddress.streetAddress2"
                  placeholder="e.g. Unit 2"
                  onChange={handleChange}
                  value={arrival?.deliveryAddress?.streetAddress2}
                  error={arrivalErrors?.deliveryAddress?.streetAddress2}
                  onBlur={handleBlur}
                  touched={touched.arrival?.deliveryAddress?.streetAddress2}
                  isErrorVisible={isErrorsVisible}
                  innerRef={isFieldInFocus?.(
                    'arrival.deliveryAddress.streetAddress2',
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  label="City"
                  name="arrival.deliveryAddress.city"
                  placeholder="City"
                  onChange={handleChange}
                  value={arrival?.deliveryAddress?.city}
                  error={arrivalErrors?.deliveryAddress?.city}
                  onBlur={handleBlur}
                  touched={touched.arrival?.deliveryAddress?.city}
                  isErrorVisible={isErrorsVisible}
                  innerRef={isFieldInFocus?.('arrival.deliveryAddress.city')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  label="State"
                  name="arrival.deliveryAddress.state"
                  placeholder="State"
                  onChange={handleChange}
                  value={arrival?.deliveryAddress?.state}
                  error={arrivalErrors?.deliveryAddress?.state}
                  onBlur={handleBlur}
                  touched={touched.arrival?.deliveryAddress?.state}
                  isErrorVisible={isErrorsVisible}
                  innerRef={isFieldInFocus?.('arrival.deliveryAddress.state')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  label="Post Code"
                  name="arrival.deliveryAddress.postcode"
                  placeholder="8888"
                  onChange={handleChange}
                  value={arrival?.deliveryAddress?.postcode}
                  error={String(arrivalErrors?.deliveryAddress?.postcode ?? '')}
                  onBlur={handleBlur}
                  touched={touched.arrival?.deliveryAddress?.postcode}
                  isErrorVisible={isErrorsVisible}
                  innerRef={isFieldInFocus?.(
                    'arrival.deliveryAddress.postcode',
                  )}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
