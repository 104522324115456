import React from 'react';

import {
  Checkbox,
  Input,
  Radio,
} from '@Dogtainers/dgt-blocks/src/components/form';
import { Grid, Typography } from '@material-ui/core';

import { PageFormProps } from '../booking.types';
import useStyles from './styles';

export const BookingPage6: React.FC<PageFormProps> = ({
  values: { options },
  handleChange,
  errors: { options: optionsErrors },
  handleBlur,
  touched,
  children,
  isFieldInFocus,
  isErrorsVisible,
}) => {
  const { radioButtonsGrid, confirmContainer, tvSeriesParagraph } = useStyles();
  return (
    <Grid container justifyContent="center" spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h4">
          <b>Booking Form</b>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          <b>Review</b>
        </Typography>
      </Grid>
      {children}
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Input
              label="Any additional notes?"
              name="options.comments"
              placeholder="Note here anything else we may need"
              multiline={true}
              rows={3}
              onChange={handleChange}
              value={options?.comments}
              error={optionsErrors?.comments}
              onBlur={handleBlur}
              touched={touched.options?.comments}
              isErrorVisible={isErrorsVisible}
              innerRef={isFieldInFocus?.('options.comments')}
            />
          </Grid>
          <Grid item xs={12} md={6} className={radioButtonsGrid}>
            <Radio
              label="Who will be contactable during your pet's flight?"
              name="options.contactDuringPetFlight"
              onChange={handleChange}
              menuItems={[
                {
                  label: 'Me',
                  value: 'me',
                },
                {
                  label: 'Sender',
                  value: 'sender',
                },
                {
                  label: 'Reciever',
                  value: 'reciever',
                },
                {
                  label: 'Other',
                  value: 'other',
                  additionalText: 'Please include their details in notes',
                },
              ]}
              value={options?.contactDuringPetFlight}
              error={optionsErrors?.contactDuringPetFlight}
              isErrorVisible={isErrorsVisible}
              innerRef={isFieldInFocus?.('options.contactDuringPetFlight')}
            />
          </Grid>
          <Grid item xs={12} className={confirmContainer}>
            <Checkbox
              label=""
              menuItems={[
                {
                  name: 'options.confirmAllInformation',
                  label:
                    'I confirm that all information I have provided within this booking submission is correct and understand that if this differs from the original quote in any way, additional charges will apply. I appoint Dogtainers Australia as my pet transporter and I have read and understood the standard terms & conditions as listed on our website * ',
                  value: !!options?.confirmAllInformation,
                },
              ]}
              onChange={handleChange}
              error={String(optionsErrors?.confirmAllInformation ?? '')}
              isErrorVisible={isErrorsVisible}
              innerRef={isFieldInFocus?.('options.confirmAllInformation')}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography className={tvSeriesParagraph}>
              Dogtainers is currently the subject of an informative TV series
              covering the transportation of Pets. Please advise if you are
              interested in you and your pet being a part in the series.
            </Typography>
            <Radio
              label=""
              name="options.interestedInTVSeries"
              onChange={handleChange}
              required={false}
              menuItems={[
                {
                  label: 'Yes, I am interested',
                  value: true,
                },
                {
                  label: 'No, I am not interested',
                  value: false,
                },
              ]}
              value={options?.interestedInTVSeries}
              error={String(optionsErrors?.interestedInTVSeries)}
              isErrorVisible={isErrorsVisible}
              innerRef={isFieldInFocus?.('options.interestedInTVSeries')}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
