import React from 'react';

import { DatePicker, Input } from '@Dogtainers/dgt-blocks/src/components/form';
import { Grid } from '@material-ui/core';

import { MediaTypeProps } from '../types';

const Digital: React.FC<MediaTypeProps> = ({
  handleChange,
  values,
  errors: { mediaEnquiry: mediaEnquiryError },
}) => {
  const { digital } = values || {};
  const { digital: digitalErrors } = mediaEnquiryError || {};

  return (
    <>
      <Grid item>
        <Input
          label="Company website"
          name="mediaEnquiry.digital.digitalCompanyWebsite"
          placeholder="e.g. publication.com.au"
          onChange={handleChange}
          value={digital?.digitalCompanyWebsite}
          error={digitalErrors?.digitalCompanyWebsite}
        />
      </Grid>
      <Grid item>
        <DatePicker
          label="Digital publication date"
          name="mediaEnquiry.digital.digitalPublicationDate"
          onChange={handleChange}
          value={digital?.digitalPublicationDate}
          error={digitalErrors?.digitalPublicationDate}
        />
      </Grid>
      <Grid item>
        <Input
          label="Digital Publication topic"
          name="mediaEnquiry.digital.digitalTopic"
          placeholder="e.g. Pet Travel"
          onChange={handleChange}
          value={digital?.digitalTopic}
          error={digitalErrors?.digitalTopic}
        />
      </Grid>
      <Grid item>
        <Input
          multiline
          rows={6}
          label="Digital Publication brief"
          placeholder="Give us a quick rundown of the project and any info you think we may need."
          name="mediaEnquiry.digital.digitalBrief"
          onChange={handleChange}
          value={digital?.digitalBrief}
          error={digitalErrors?.digitalBrief}
        />
      </Grid>
    </>
  );
};

export default Digital;
