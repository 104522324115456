import { FormikTouched } from 'formik';
import React, { useMemo } from 'react';

import {
  Autocomplete,
  Checkbox,
  DatePicker,
  Radio,
} from '@Dogtainers/dgt-blocks/src/components/form';
import { useIsMobile } from '@Dogtainers/dgt-blocks/src/hooks/useBreakpoints';
import { Grid, Typography } from '@material-ui/core';

import { defaultLocale } from '../../../utils/language';
import {
  handleAutoFocus,
  handleFocusField,
  isAULocale,
  isGBLocale,
} from '../../utils';
import { DestinationFields } from '../components/DestinationFields';
import { usePickupAndDeliveryAddress } from '../hooks/usePickupAndDeliveryAddress';
import { PageFormProps } from '../quote.form';
import { BookingQuote, QuoteFormPartial } from '../quote.types';
import { isHiddenField } from '../quote.validation';

export const QuotePage2: React.FC<PageFormProps> = ({
  values,
  handleChange,
  setFieldValue,
  errors,
  touched,
  handleBlur,
  isErrorsVisible,
  isFieldInFocus,
  children,
}) => {
  const isMobile = useIsMobile();
  const {
    arrival,
    departure,
    pickupAddress,
    deliveryAddress,
    pickupAddresses,
    deliveryAddresses,
    handleInputMapChange,
    handleAddressChange,
    generateAutocompleteItems,
  } = usePickupAndDeliveryAddress({
    values,
    handleChange,
    setFieldValue,
  });

  const handleDepartureDateChange = (e: React.ChangeEvent<HTMLElement>) => {
    setFieldValue(
      'options.petAccommodation.start',
      (e.target as HTMLInputElement).value,
    );
    handleChange(e);
  };

  //No postcode for Hong Kong (DOG-588)
  const isHongKongDeparture = useMemo(
    () => values?.departure?.countryId === 'HK' && values?.departure?.isPickup,
    [values?.departure],
  );

  const isHongKongArrival = useMemo(
    () => values?.arrival?.countryId === 'HK' && values?.arrival?.isDelivery,
    [values?.arrival],
  );

  return (
    <Grid container justifyContent="center" spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h4">Itinerary</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          Here we need to understand what is the nearest airport for departure
          and arrival.
        </Typography>
      </Grid>
      {children}
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <DestinationFields
            values={values}
            errors={errors as QuoteFormPartial}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            handleBlur={handleBlur}
            touched={touched as FormikTouched<BookingQuote>}
            isErrorsVisible={isErrorsVisible}
            isFieldInFocus={isFieldInFocus}
          />

          <Grid item xs={12} sm={6}>
            <DatePicker
              label="Estimated Date of Travel"
              name="departure.date"
              onChange={handleDepartureDateChange}
              value={departure?.date}
              error={errors?.departure?.date}
              isErrorVisible={isErrorsVisible}
              touched={touched.departure?.date}
              onBlur={handleBlur}
              innerRef={isFieldInFocus?.('departure.date')}
              key={`${!errors?.arrival?.airportId}_departure.date`}
              autoFocus={handleAutoFocus(
                isMobile,
                !arrival?.airportId || errors?.arrival?.airportId,
                departure?.date,
              )}
              onFocus={(e) => handleFocusField(isMobile, e.target)}
            />
          </Grid>

          {!isGBLocale && (
            <Grid item xs={12} sm={6} md={3}>
              <Checkbox
                label="Special Arrangements?"
                menuItems={[
                  {
                    name: 'departure.isPickup',
                    label: 'Pickup',
                    value: !!departure?.isPickup,
                  },
                  {
                    name: 'arrival.isDelivery',
                    label: 'Delivery',
                    value: !!arrival?.isDelivery,
                  },
                ]}
                key={`${!errors?.departure?.date}_departure.isPickup`}
                autoFocus={handleAutoFocus(
                  isMobile,
                  errors?.departure?.date,
                  departure?.isPickup && arrival?.isDelivery,
                )}
                onChange={handleChange}
                onFocus={(e) => handleFocusField(isMobile, e.target)}
              />
            </Grid>
          )}

          {values.quoteType === 'international' &&
            !isHiddenField(defaultLocale, 'arrival.isWithin5Days') && (
              <Grid item xs={12} sm={6} md={3}>
                <Radio
                  name="arrival.isWithin5Days"
                  label="Do you intend to arrive within 5 days of your pet?"
                  value={arrival?.isWithin5Days}
                  error={String(errors?.arrival?.isWithin5Days)}
                  onChange={handleChange}
                  innerRef={isFieldInFocus?.('arrival.isWithin5Days')}
                  onFocus={(e) => handleFocusField(isMobile, e.target)}
                  menuItems={[
                    {
                      label: 'No',
                      value: false,
                    },
                    {
                      label: 'Yes',
                      value: true,
                    },
                  ]}
                />
              </Grid>
            )}

          {!isGBLocale && !!departure?.isPickup && !isHongKongDeparture && (
            <Grid item xs={12} sm={6}>
              <Autocomplete
                menuItems={generateAutocompleteItems(pickupAddresses)}
                placeholder={`Enter ${isAULocale ? 'postcode' : 'address'}`}
                label={`Pickup ${isAULocale ? 'Postcode' : 'Address'}`}
                name="departure.pickupAddress.address"
                onInputChange={(value: string) => {
                  handleInputMapChange(value, 'pickup');
                }}
                onChange={(e) => {
                  handleAddressChange(e, 'departure.pickupAddress');
                }}
                value={pickupAddress?.address || ''}
                error={errors?.departure?.pickupAddress?.address}
                controlledState
                bypassFilterOptions
                touched={touched.departure?.pickupAddress}
                isErrorVisible={isErrorsVisible}
                onBlur={handleBlur}
                innerRef={isFieldInFocus?.('departure.pickupAddress.address')}
                onFocus={(e) => handleFocusField(isMobile, e.target)}
              />
            </Grid>
          )}
          {!isGBLocale && !!arrival?.isDelivery && !isHongKongArrival && (
            <Grid item xs={12} sm={6}>
              <Autocomplete
                menuItems={generateAutocompleteItems(deliveryAddresses)}
                placeholder={`Enter ${isAULocale ? 'postcode' : 'address'}`}
                label={`Delivery ${isAULocale ? 'Postcode' : 'Address'}`}
                name="arrival.deliveryAddress.address"
                onInputChange={(value: string) => {
                  handleInputMapChange(value, 'delivery');
                }}
                onChange={(e) =>
                  handleAddressChange(e, 'arrival.deliveryAddress')
                }
                value={deliveryAddress?.address || ''}
                error={errors?.arrival?.deliveryAddress?.address}
                controlledState
                bypassFilterOptions
                touched={touched.arrival?.deliveryAddress}
                isErrorVisible={isErrorsVisible}
                onBlur={handleBlur}
                innerRef={isFieldInFocus?.('arrival.pickupAddress.address')}
                key={`${!errors?.departure?.pickupAddress
                  ?.address}_arrival.deliveryAddress.address`}
                autoFocus={handleAutoFocus(
                  isMobile,
                  errors?.departure?.pickupAddress?.address,
                  deliveryAddress?.address,
                )}
                onFocus={(e) => handleFocusField(isMobile, e.target)}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
