import { navigate } from 'gatsby';
import React, { useMemo } from 'react';

import { Image } from '@Dogtainers/dgt-blocks/src/components';
import {
  BlockBuilder,
  BlockThankyouSmartBooking,
} from '@Dogtainers/dgt-blocks/src/types/_blocks.types';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { WhiteButton } from '../../../components/buttons';
import { localiseUrl } from '../../../utils/language';
import useStyles from './styles';

type Props = BlockBuilder<BlockThankyouSmartBooking>;

const ThankyouSmartBooking: React.FC<Props> = ({ getImageData, block }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  const onBackHome = () => {
    const url = '/';
    navigate(localiseUrl(url));
  };

  const { title, subtitle, content } = useMemo(
    () => block.items[0] || {},
    [block],
  );

  const { root, tyHeader, tyTitle, tySubTitle } = useStyles();

  return (
    <Container maxWidth="sm" className={root}>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item xs={12}>
          <Box className={tyHeader}>
            <Typography variant={matches ? 'h5' : 'h4'} className={tyTitle}>
              {title}
            </Typography>
            <Typography className={tySubTitle}>{subtitle}</Typography>
          </Box>

          <Box mb={4}>
            <Typography
              dangerouslySetInnerHTML={{ __html: content }}
            ></Typography>
          </Box>

          <Box mb={5}>
            <Box mb={2}>
              <Image
                getImageData={getImageData}
                src={'dog-thankyou.webp'}
                alt="thankyou"
                options={{
                  width: '100%',
                  height: 'auto',
                }}
              />
            </Box>
            {/* <Typography>
              Image caption lorem ipsum dolor sit amet, consectetur adipiscing
              elit, sed do eiusmod tempor incididunt ut labore et dolore magna
              aliqua.
            </Typography> */}
          </Box>

          <Box
            display="flex"
            justifyContent="flex-end"
            flexDirection={!matches ? 'row' : 'column'}
            mb={4}
          >
            <WhiteButton variant="outlined" onClick={onBackHome}>
              Back Home
            </WhiteButton>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ThankyouSmartBooking;
