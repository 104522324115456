import 'yup-phone';

import * as yup from 'yup';

import { Locale } from '../../typings/i18n.types';
import { localeCountryId } from '../../utils/language';
import {
  emailRegExp,
  getPostcodeRegex,
  getSpaceErrorMessage,
  phoneRegExp,
  spaceInsideRegex,
} from '../utils/regex';
import {
  generateDate,
  generateMaxDate,
  validateDatesByDayDiff,
} from '../utils/validation';
import { isExtraDataMandatory } from './utils';

export const initValidationSchema = (
  localeCountryId: string, // e.g. AU, NZ, GB
): ReturnType<typeof yup.object> =>
  yup.object({
    quoteType: yup.string().required('Please select domestic or international'),
    departure: yup
      .object({
        countryId: yup
          .string()
          .test(
            'departureCountry',
            'Departure country is required',
            requiredInternational,
          ),
        airportId: yup
          .string()
          .test(
            'departure.airportId',
            'Please select a departure airport',
            airportId,
          ),
        date: yup
          .date()
          .required('Please select a departure date')
          .min(
            generateDate(),
            'Please select a departure date that is not in the past',
          )
          .max(
            generateMaxDate(new Date(), 24),
            'Please select a departure date that is not more than 24 months in the future',
          ),
        isPickup: yup.boolean(),
        pickupAddress: yup.object({
          address: yup
            .string()
            .test(
              'pickupAddress.address',
              `Pickup ${
                localeCountryId === 'AU' ? 'postcode' : 'address'
              } is required`,
              (value, context) => {
                const { from } = context as QuoteFormContext;
                const isPickup = from[2]?.value?.departure?.isPickup;
                const quoteType = from[2]?.value?.quoteType;
                const departureCountryId = from[2]?.value?.departure?.countryId;

                if (departureCountryId === 'HK' && isPickup) return true;

                return isPickup ||
                  (localeCountryId === 'GB' && quoteType === 'domestic')
                  ? value !== undefined && value !== null && value !== ''
                  : true;
              },
            ),
        }),
      })
      .required(),
    arrival: yup
      .object({
        countryId: yup
          .string()
          .test(
            'arrivalCountry',
            'Arrival country is required',
            requiredInternational,
          ),
        airportId: yup
          .string()
          .test(
            'arrival.airportId',
            'Please select an arrival airport',
            airportId,
          )
          .test(
            'arrival.airportId',
            'Please select arrival airport different from departure airport',
            (value, context) => {
              const { from } = context as QuoteFormContext;
              return isUKDomestic(from[1])
                ? true
                : from[1]?.value?.departure?.airportId === value
                ? false
                : true;
            },
          ),
        isDelivery: yup.boolean(),
        deliveryAddress: yup.object({
          address: yup
            .string()
            .test(
              'deliveryAddress.address',
              `Delivery ${
                localeCountryId === 'AU' ? 'postcode' : 'address'
              } is required`,
              (value, context) => {
                const { from } = context as QuoteFormContext;
                const isDelivery = from[2]?.value?.arrival?.isDelivery;
                const quoteType = from[2]?.value?.quoteType;
                const arrivalCountryId = from[2]?.value?.arrival?.countryId;

                if (arrivalCountryId === 'HK' && isDelivery) return true;

                return isDelivery ||
                  (localeCountryId === 'GB' && quoteType === 'domestic')
                  ? value !== undefined && value !== null && value !== ''
                  : true;
              },
            ),
        }),
        isWithin5Days: yup
          .boolean()
          .test(
            'isWithin5Days',
            'Please specify if arriving within 5 days',
            requiredInternational,
          ),
      })
      .required(),
    contact: yup
      .object({
        nameFirst: yup
          .string()
          .matches(
            /^\s*([a-zA-Z]+[ -]?)*[a-zA-Z]+\s*$/,
            'Please enter valid name',
          )
          .min(2, 'Too Short!')
          .max(40)
          .required('First name is required'),
        nameLast: yup
          .string()
          .matches(
            /^\s*([a-zA-Z]+[ -]?)*[a-zA-Z]+\s*$/,
            'Please enter valid name',
          )
          .min(2, 'Too Short!')
          .max(40)
          .required('Last name is required'),
        email: yup
          .string()
          .max(50)
          .matches(emailRegExp, 'Please enter valid email')
          .matches(spaceInsideRegex, (value) =>
            getSpaceErrorMessage(value.value),
          )
          .required('Email is required'),
        phone: yup
          .string()
          .matches(phoneRegExp, 'Phone number is not valid')
          .required('Phone is required'),
        countryId: yup.string().required('Please select country'),
        postcode: yup
          .string()
          .test('contact.postcode', 'Invalid postcode', (val = '', context) => {
            // No postcode needed for NZ site (see Jira DOG-18)
            if (localeCountryId === 'NZ') {
              return true;
            }
            // Regex validation
            const { from } = context as QuoteFormContext;
            const parent = from[0];
            const quoteType = from[from.length - 1]?.value?.quoteType;
            const defaultCountryId =
              quoteType === 'domestic' ? localeCountryId : '';
            const contactCountryId =
              parent?.value?.countryId || defaultCountryId;

            const isPostcodeValid =
              getPostcodeRegex(contactCountryId).test(val);

            if (contactCountryId === 'HK') return true;

            if (localeCountryId === 'AU') return isPostcodeValid;
            const departureCountry =
              from[from.length - 1]?.value?.departure?.countryId;

            const isQuoteTypeInternational = quoteType === 'international';
            const isDepartureCountryUK = departureCountry === 'GB';

            if (!isDepartureCountryUK || !isQuoteTypeInternational)
              return isPostcodeValid || val === '';

            return isPostcodeValid;
          }),
      })
      .required(),
    pets: yup
      .array()
      .of(
        yup.object({
          name: yup
            .string()
            .matches(
              /^\s*([a-zA-Z]+[ -]?)*[a-zA-Z]+\s*$/,
              'Please enter valid pet name',
            )
            .required('Pet name is required'),
          ageId: yup.string().required('Please select your pet age'),
          typeId: yup.string().required('Please select pet type'),
          subtypeId: yup.string().when('typeId', {
            // Subtype only required for these pet types
            is: (val: string) => ['bird'].includes(val),
            then: yup.string().required('Please specify a pet subtype'),
          }),
          breedId: yup.string().when('typeId', {
            // Breed ID only required for these pet types
            is: (val: string) =>
              ['cat', 'dog', 'bird', 'rabbit', 'other'].includes(val),
            then: yup.string().required('Please select a breed'),
          }),
          weight: yup
            .number()
            .moreThan(0, 'Pet weight must be bigger than 0')
            .max(200, 'Max value 200.')
            .typeError('Pet weight must be a number')
            .required('Pet weight is required'),
          neutered: yup
            .boolean()
            .test(
              'quoteType',
              'Please select if pet is neutered',
              isExtraPetDataMandatory(localeCountryId),
            ),
          sex: yup.string(),
          crate: yup.object().when(['typeId'], (typeId, schema) => {
            if (!['insect', 'amphibian'].includes(typeId)) {
              return schema.shape({
                type: yup
                  .string()
                  .test(
                    'pets.crate',
                    'Please select a crate type',
                    crateValidation,
                  ),
                length: yup.number().when('type', {
                  is: 'own',
                  then: yup
                    .number()
                    .moreThan(0, 'Crate length must be bigger than 0')
                    .test(
                      'pets.crate.lenght',
                      'Crate length is required',
                      crateValidation,
                    ),
                }),
                height: yup.number().when('type', {
                  is: 'own',
                  then: yup
                    .number()
                    .moreThan(0, 'Crate height must be bigger than 0')
                    .test(
                      'pets.crate.height',
                      'Crate height is required',
                      crateValidation,
                    ),
                }),
                width: yup.number().when('type', {
                  is: 'own',
                  then: yup
                    .number()
                    .moreThan(0, 'Crate width must be bigger than 0')
                    .test(
                      'pets.crate.width',
                      'Crate width is required',
                      crateValidation,
                    ),
                }),
              });
            } else {
              // If typeId is 'insect' or 'amphibian', no crate validation is applied
              return schema;
            }
          }),
        }),
      )
      .required(),
    options: yup
      .object({
        // Return Flight
        requiresReturnFlight: yup
          .boolean()
          .required('Please specify if return flight is required'),
        returnFlightDate: yup.string().when('requiresReturnFlight', {
          is: true,
          then: yup
            .string()
            .required('Please select a date')
            .test(
              'options.returnFlightDate',
              'Please select date that is at least 3 days after departure date',
              validateDatesByDayDiff('from[1].value.departure.date', 3),
            ),
        }),
        requiresPetAccommodation: yup
          .boolean()
          .required('Please specify if pet accommodation required'),
        petAccommodation: yup.object().when('requiresPetAccommodation', {
          is: true,
          then: yup.object({
            start: yup.string().required('Please select a date'),
            end: yup
              .string()
              .required('Please select a date')
              .test(
                'options.petAccommodation.end',
                'Please select date that is at least 1 day after start date',
                validateDatesByDayDiff('parent.start', 1),
              ),
          }),
        }),
        // Pet quarantine
        isExQuarantine: yup
          .boolean()
          .required('Please specify if your pet will be exiting quarantine'),
        quarantine: yup.object().when('isExQuarantine', {
          is: true,
          then: yup.object({
            start: yup
              .string()
              .test(
                'quarantineStart',
                'Quarantine start is required',
                requiredQuarantine,
              )
              .required('Please select a date'),
            end: yup
              .string()
              .test(
                'quarantineEnd',
                'Quarantine end is required',
                requiredQuarantine,
              )
              .required('Please select a date'),
          }),
        }),
        isMoving: yup.boolean().required('Please specify if you are moving'),
        isDataSharingEnabled: yup.boolean().when('isMoving', {
          is: true,
          then: yup
            .boolean()
            .required(
              'Please specify if we can share your details with a partner',
            ),
          otherwise: yup.boolean(),
        }),
        comments: yup.string(),
      })
      .required(),
  });

/**
 * Helper validation fn to check parent property states
 */
const requiredInternational: yup.TestFunction = (val, context) => {
  const { from } = context as QuoteFormContext;
  const grandparent = from[1];
  const isInternational = grandparent?.value?.quoteType === 'international';
  if (!isInternational) {
    return true;
  }
  return typeof val !== 'undefined';
};

const isUKDomestic = (context: yup.TestOptions) =>
  context?.value?.quoteType === 'domestic' && localeCountryId === 'GB';

const airportId: yup.TestFunction = (value, context) => {
  const { from } = context as QuoteFormContext;
  return isUKDomestic(from[1]) ? true : typeof value !== 'undefined';
};

const crateValidation: yup.TestFunction = (val, context) => {
  const { from } = context as QuoteFormContext;
  const isValueValid =
    typeof val === 'number'
      ? !isNaN(val)
      : typeof val === 'string' && ['buy', 'hire', 'own'].includes(val || '');

  return isUKDomestic(from[from.length - 1]) || isValueValid;
};

const requiredQuarantine: yup.TestFunction = (val, context) => {
  const { from } = context as QuoteFormContext;
  const parent = from[0];
  const isExQuarantine = parent?.value?.isExQuarantine === true;
  if (!isExQuarantine) {
    return true;
  }
  return typeof val !== 'undefined';
};

const isExtraPetDataMandatory =
  (localeCountryId: string): yup.TestFunction =>
  (val, context) => {
    const { from } = context as QuoteFormContext;
    const quoteType = from[1].value.quoteType;
    const pet = from[0].value.typeId;

    return !(
      isExtraDataMandatory(localeCountryId, quoteType, pet) && val === undefined
    );
  };

type QuoteFormContext = yup.TestContext & {
  from: yup.TestOptions[];
};

export function isHiddenField(locale: Locale, field: string): boolean {
  switch (locale) {
    case 'en-GB':
      return [
        'arrival.isWithin5Days',
        'options.requiresReturnFlight',
        'options.isExQuarantine',
        'options.isMoving',
        'options.isAustralianDefenceForceMember',
      ].includes(field);
    case 'en-NZ':
      return [
        'contact.postcode',
        'options.isAustralianDefenceForceMember',
      ].includes(field);
    default:
      return false;
  }
}

export function mapFieldToPage(field: string): number {
  // Page 1
  if (['quoteType'].includes(field)) {
    return 1;
  }
  // Page 2
  if (field.startsWith('departure.') || field.startsWith('arrival.')) {
    return 2;
  }
  // Page 3
  if (field.startsWith('contact.')) {
    return 3;
  }
  // Page 4
  if (field.startsWith('pets.')) {
    return 4;
  }
  // Page 5 - default
  return 5;
}
