import React from 'react';

import { DatePicker, Input } from '@Dogtainers/dgt-blocks/src/components/form';
import { Grid } from '@material-ui/core';

import { MediaTypeProps } from '../types';

const PodcastRadio: React.FC<MediaTypeProps> = ({
  handleChange,
  values,
  errors: { mediaEnquiry: mediaEnquiryError },
}) => {
  const { podcastRadio } = values || {};
  const { podcastRadio: podcastRadioErrors } = mediaEnquiryError || {};

  return (
    <>
      <Grid item>
        <Input
          label="Company website / Streaming platform link"
          name="mediaEnquiry.podcastRadio.podcastCompanyWebsite"
          placeholder="e.g. podcast.com.au"
          onChange={handleChange}
          value={podcastRadio?.podcastCompanyWebsite}
          error={podcastRadioErrors?.podcastCompanyWebsite}
        />
      </Grid>
      <Grid item>
        <DatePicker
          label="Recording date"
          name="mediaEnquiry.podcastRadio.podcastRadioRecordingDate"
          onChange={handleChange}
          value={podcastRadio?.podcastRadioRecordingDate}
          error={podcastRadioErrors?.podcastRadioRecordingDate}
        />
      </Grid>
      <Grid item>
        <Input
          label="Podcast / Radio show topic"
          name="mediaEnquiry.podcastRadio.podcastRadioTopic"
          placeholder="e.g. Pet Travel"
          onChange={handleChange}
          value={podcastRadio?.podcastRadioTopic}
          error={podcastRadioErrors?.podcastRadioTopic}
        />
      </Grid>
      <Grid item>
        <Input
          multiline
          rows={6}
          label="Podcast / Radio show brief"
          placeholder="Give us a quick rundown of the project and any info you think we may need."
          name="mediaEnquiry.podcastRadio.podcastRadioBrief"
          onChange={handleChange}
          value={podcastRadio?.podcastRadioBrief}
          error={podcastRadioErrors?.podcastRadioBrief}
        />
      </Grid>
    </>
  );
};

export default PodcastRadio;
