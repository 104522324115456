import React from 'react';

import {
  Input,
  Radio,
  RatingSlider,
  RatingStars,
} from '@Dogtainers/dgt-blocks/src/components/form';
import { Grid } from '@material-ui/core';

import { ContactFormPartial, RadioToggleType } from '../../contact.types';

type FeedbackProps = {
  values: ContactFormPartial;
  handleChange: (
    event: React.ChangeEvent<HTMLElement | Record<string, unknown>>,
  ) => void;
  setFieldValue: (name: string, value: string) => void;
  errors: ContactFormPartial;
  radioToggleItems: RadioToggleType;
  submitSection: JSX.Element;
};

type RatingSliderValueType =
  | 'poor'
  | 'subpar'
  | 'satisfactory'
  | 'good'
  | 'outstanding'
  | undefined;

const Feedback: React.FC<FeedbackProps> = (props) => {
  const {
    values: { feedback },
    handleChange,
    errors: { feedback: feedbackErrors },
    setFieldValue,
    radioToggleItems,
    submitSection,
  } = props;

  return (
    <>
      <Grid item>
        <RatingStars
          label="Overall Rating"
          name="feedback.rating"
          onChange={handleChange}
          value={feedback?.rating}
          error={feedbackErrors?.rating}
          isErrorVisible={true}
        />
      </Grid>
      <Grid item>
        <RatingSlider
          name="feedback.quoteProcessRating"
          label="Quote Process Rating"
          setFieldValue={setFieldValue}
          value={feedback?.quoteProcessRating as RatingSliderValueType}
        />
      </Grid>
      <Grid item>
        <RatingSlider
          name="feedback.teamMemberServiceRating"
          label="Team Member Service Rating"
          setFieldValue={setFieldValue}
          value={feedback?.teamMemberServiceRating as RatingSliderValueType}
        />
      </Grid>
      <Grid item>
        <RatingSlider
          name="feedback.tripRating"
          label="Trip Rating"
          setFieldValue={setFieldValue}
          value={feedback?.tripRating as RatingSliderValueType}
        />
      </Grid>
      <Grid item>
        <Input
          multiline
          rows={6}
          label="Tell us about your experience"
          placeholder="How did we do?"
          name="feedback.experience"
          onChange={handleChange}
          value={feedback?.experience}
          error={feedbackErrors?.experience}
        />
      </Grid>
      <Grid item>
        <Radio
          label="Would you like to be contacted regarding your enquiry?"
          name="feedback.isContactRequired"
          onChange={handleChange}
          menuItems={radioToggleItems}
          value={feedback?.isContactRequired}
          error={String(feedbackErrors?.isContactRequired)}
        />
      </Grid>
      {submitSection}
    </>
  );
};

export default Feedback;
