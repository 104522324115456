/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { FC, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

import { BlockFaq } from '@Dogtainers/dgt-blocks/src/types/_blocks.types';

import { DEFAULT_LOCALE } from '../environment';
import { TemplateProps } from '../templates/template.types';
import { Locale } from '../typings/i18n.types';
import { fallbackLocale } from '../utils/language';
import {
  generateCareerDrive,
  generateDomesticImageMeta,
  generateDomesticVideoMeta,
  generateFAQ,
  generateFaqPageMeta,
  generateHomeMeta,
  generateIpataMeta,
  generateLocationMeta,
  generateTVSeriesSchema,
  generateWebPageSchema,
  mapPageIdToHreflangTags,
} from './seo.utils';

const ImageIds = [
  'domesticPetTransport',
  'domesticRoadPetTransport',
  'internationalPetTransportPetTransportNewZealand',
];

const webSchemaIds = ['virgin', 'airlines', 'qantas'];
const pagesWithFaq = [
  ...webSchemaIds,
  'ipata',
  'domesticRoadPetTransport',
  'domesticPetTransport',
  'internationalPetTransport',
  'catTransport',
  'petTransportImportServices',
  'dogTransport',
  'petTravelBreeders',
  'zooAnimalTransport',
  'internationalPetTransportPetTransportUSA',
  'crateHire',
  'petsFromQuarantine',
  'internationalPetTransportPetTransportCanada',
  'internationalPetTransportPetTransportUnitedKingdom',
  'internationalPetTransportPetTransportEurope',
  'internationalPetTransportPetTransportNewZealand',
  'locationSydneySyd',
  'flyPets',
  'petFlights',
  'shipping',
  'locationBrisbaneBne',
  'petTravelGlobal',
];
const pagesWithDifferentFaqForGB = [
  'internationalPetTransportPetTransportSouthAfrica',
  'internationalPetTransportPetTransportQatar',
  'internationalPetTransportPetTransportAustralia',
  'internationalPetTransportPetTransportUnitedArabEmirates',
  'catTransport',
  'petTravelGlobal',
  'crateHire',
  'petFlights',
  'petTransportImportServices',
  'petTravelPreparation',
  'petTypes',
  'petsFromQuarantine',
  'shipping',
  'internationalPetTransportPetTransportCanada',
  'dogTransport',
  'internationalPetTransportPetTransport',
  'internationalPetTransportPetTransportUSA',
];
const pagesWithDifferentFaqForNZ = [
  'internationalPetTransport',
  'internationalPetTransportPetTransportEurope',
  'internationalPetTransportPetTransportSouthAfrica',
  'internationalPetTransportPetTransportUnitedArabEmirates',
  'petTravelGlobal',
];

const localeCountryId = (DEFAULT_LOCALE as Locale).slice(-2);

export const Seo: FC<TemplateProps> = (props) => {
  const {
    location: { pathname },
    pageContext: { pageId, blocks, baseLocale },
  } = props;

  const {
    messages: {
      locale,
      siteTitle,
      title: pageTitle,
      description: siteDescription,
      metaDescription,
    },
  } = useIntl();

  const faqBlock = useMemo(
    () => blocks.find((b): b is BlockFaq => b.type === 'faq'),
    [blocks],
  );

  const title = useMemo(
    () => `${pageTitle && pageTitle !== '$' ? pageTitle : siteTitle}`,
    [pageTitle],
  );

  const baseOrFallbackLocale = useMemo(() => baseLocale ?? fallbackLocale, []);

  const metaScripts = useMemo(() => {
    const res = [];

    faqBlock && res.push(generateFaqPageMeta(faqBlock));

    ImageIds.includes(pageId) && res.push(generateDomesticImageMeta(pageId));

    pathname !== '/contact-us/' &&
      pathname !== '/quote/' &&
      res.push(generateHomeMeta(pathname === '/'));

    /^\/location\/.+/.test(pathname) &&
      res.push(generateLocationMeta(blocks[0], pathname));

    if (baseOrFallbackLocale === 'en-AU') {
      pathname === '/careers/driver/' && res.push(generateCareerDrive());

      pageId === 'ipata' && res.push(generateIpataMeta());

      webSchemaIds.includes(pageId) && res.push(generateWebPageSchema(pageId));
    }

    localeCountryId !== 'GB' &&
      pageId === 'domesticPetTransport' &&
      res.push(generateDomesticVideoMeta());

    if (localeCountryId === 'AU' && pagesWithFaq.includes(pageId)) {
      res.push(generateFAQ(pageId));
    }

    //different FAQs for NZ/GB domain pages compared to AU
    const countryConfig: { [key: string]: string[] } = {
      NZ: pagesWithDifferentFaqForNZ,
      GB: pagesWithDifferentFaqForGB,
    };

    if (countryConfig[localeCountryId]?.includes(pageId)) {
      res.push(generateFAQ(pageId + localeCountryId));
    }

    pageId === 'animalsAboardTvSeries' &&
      res.push(generateTVSeriesSchema(pageId));

    return res;
  }, [faqBlock, baseOrFallbackLocale]);

  const hreflangTags = useMemo(
    () =>
      mapPageIdToHreflangTags[
        pageId + (localeCountryId === 'GB' ? localeCountryId : '')
      ] ?? [],
    [pageId],
  );

  return (
    <Helmet
      htmlAttributes={{
        lang: locale as string,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: (metaDescription || siteDescription) as string,
        },
        {
          property: `og:title`,
          content: title as string,
        },
        {
          property: `og:description`,
          content: (metaDescription || siteDescription) as string,
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ]}
    >
      {metaScripts?.map((script) => (
        <script key={script.type} type={script.type}>
          {JSON.stringify(script.content).replace(/</g, '\\u003c')}
        </script>
      ))}
      {/* hreflang tags */}
      {hreflangTags.map(({ href, hreflang }) => (
        <link key={href} rel="alternate" hrefLang={hreflang} href={href} />
      ))}

      {/**
       * This is a secondary GTM code to be appended to all sites
       * hacky AF, but GTM isn't designed to have multiple codes, so ¯\_(ツ)_/¯
       * @see https://litepages.atlassian.net/browse/DOG-59
       */}
      {/* eslint-disable @typescript-eslint/no-explicit-any */}
      <script>
        {typeof window !== 'undefined' &&
          (function (w, d, s, l, i): any {
            w[l] = w[l] || [];
            w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
            const f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != 'dataLayer' ? '&l=' + l : '';
            (j as any).async = true;
            (j as any).src =
              'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            (f as any).parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', 'GTM-W6N2SMH')}
      </script>
    </Helmet>
  );
};
