import React, { useEffect, useMemo, useState } from 'react';

import {
  Autocomplete,
  Checkbox,
  DatePicker,
  Radio,
} from '@Dogtainers/dgt-blocks/src/components/form';
import { AutocompleteItem } from '@Dogtainers/dgt-blocks/src/components/form/autocomplete';
import { Grid, Typography } from '@material-ui/core';

import { PageFormProps } from '../booking.types';
import { Airport } from '../booking.types';
import useStyles from './styles';

const getAirports = (
  countryId: string,
  allAirports: Airport[],
): AutocompleteItem[] => {
  if (!allAirports.length) {
    return [];
  }
  const countryAirports = countryId
    ? allAirports.filter((a) => a.countryCode === countryId)
    : allAirports;
  const filteredAirports = countryAirports.length
    ? countryAirports
    : allAirports;
  return filteredAirports.map((a) => ({ value: a.id, label: a.label }));
};

const travellingByItems = [
  {
    label: 'Flights',
    value: 'flights',
  },
  {
    label: 'Road Transport',
    value: 'roadTransport',
  },
];

export const BookingPage2: React.FC<PageFormProps> = ({
  values: {
    departure,
    arrival,
    petTravellingBy,
    hearMoreAboutProducts,
    quotedAirline,
  },
  handleChange,
  errors: {
    departure: departureErrors,
    arrival: arrivalErrors,
    petTravellingBy: petTravellingByError,
    quotedAirline: quotedAirlineError,
  },
  isValueEqual,
  handleBlur,
  touched,
  isErrorsVisible,
  isFieldInFocus,
  children,
}) => {
  const [allAirports, setAllAirports] = useState<Airport[]>([]);
  const { pageTitle, pageSubTitle } = useStyles();

  const departureAirports: AutocompleteItem[] = useMemo(
    () => getAirports('AU', allAirports),
    [allAirports],
  );

  useEffect(() => {
    fetch('/data/airports.json').then((response) => {
      response.json().then((body: Airport[]) => {
        setAllAirports(body);
      });
    });
  }, []);

  return (
    <Grid container justifyContent="center" spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h4" className={pageTitle}>
          Booking Form
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" className={pageSubTitle}>
          Customer and Travel Information
        </Typography>
      </Grid>
      {children}
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <DatePicker
              label="Preferred Departure Date"
              name="departure.date"
              onChange={handleChange}
              value={departure?.date}
              error={departureErrors?.date}
              onBlur={handleBlur}
              touched={touched.departure?.date}
              isErrorVisible={isErrorsVisible}
              innerRef={isFieldInFocus?.('departure.date')}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DatePicker
              label="Preferred Arrival Date"
              name="arrival.date"
              onChange={handleChange}
              value={arrival?.date}
              error={arrivalErrors?.date}
              onBlur={handleBlur}
              touched={touched.arrival?.date}
              isErrorVisible={isErrorsVisible}
              innerRef={isFieldInFocus?.('arrival.date')}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              label="Origin Port"
              name="departure.airportId"
              menuItems={departureAirports}
              onChange={handleChange}
              value={departure?.airportId}
              error={departureErrors?.airportId}
              onBlur={handleBlur}
              touched={touched.departure?.airportId}
              isErrorVisible={isErrorsVisible}
              innerRef={isFieldInFocus?.('departure.airportId')}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              label="Destination Port"
              name="arrival.airportId"
              menuItems={departureAirports}
              onChange={handleChange}
              value={arrival?.airportId}
              error={arrivalErrors?.airportId}
              onBlur={handleBlur}
              touched={touched.arrival?.airportId}
              isErrorVisible={isErrorsVisible}
              innerRef={isFieldInFocus?.('arrival.airportId')}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Radio
              label="My Pet is travelling by:"
              name="petTravellingBy"
              menuItems={travellingByItems}
              onChange={handleChange}
              value={petTravellingBy}
              error={petTravellingByError}
              isErrorVisible={isErrorsVisible}
              innerRef={isFieldInFocus?.('petTravellingBy')}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Checkbox
              label="I would like to hear more about these products"
              menuItems={[
                {
                  label: 'Adaptil Collar',
                  name: 'hearMoreAboutProducts.adaptilCollar',
                  value: !!hearMoreAboutProducts?.adaptilCollar,
                },
                {
                  label: 'Deluxe Vet Bedding',
                  name: 'hearMoreAboutProducts.deluxeVetBedding',
                  value: !!hearMoreAboutProducts?.deluxeVetBedding,
                },
              ]}
              onChange={handleChange}
            />
          </Grid>

          {isValueEqual('petTravellingBy', 'flights') && (
            <Grid item xs={12} md={6}>
              <Radio
                label="Quoted Airline"
                name="quotedAirline"
                value={quotedAirline}
                error={quotedAirlineError}
                onChange={handleChange}
                menuItems={[
                  {
                    label: 'Qantas',
                    value: 'qantas',
                  },
                  {
                    label: 'Virgin',
                    value: 'virgin',
                  },
                  {
                    label: 'REX',
                    value: 'rex',
                  },
                ]}
                isErrorVisible={isErrorsVisible}
                innerRef={isFieldInFocus?.('quotedAirline')}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
