import classNames from 'classnames';
import React, { FC } from 'react';

import { useErrorStyles } from '@Dogtainers/dgt-blocks/src/components/form/form.styles';
import { makeBlockStyles } from '@Dogtainers/dgt-blocks/src/hooks/useStyles';
import { Button, Grid, Typography } from '@material-ui/core';

import { ErrorMsg } from '../../forms/utils/validation';

type ErrorProps = {
  errorMsgs: ErrorMsg[];
  onClickError: (field: string) => void;
  formElementId: string;
};

const useRootFormStyles = makeBlockStyles(() => ({
  root: {
    //
  },
  errorList: {
    padding: 0,
    margin: '16px 0',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  error: {
    margin: 0,
    padding: 0,
    verticalAlign: 'unset',
    textDecoration: 'underline',
    textAlign: 'left',
  },
}));

export const ErrorBlock: FC<ErrorProps> = ({
  errorMsgs,
  onClickError,
  formElementId,
}) => {
  const classes = useRootFormStyles();
  const { errorFont, errorBlock } = useErrorStyles();

  return (
    <Grid item xs={12} id={formElementId}>
      <Typography variant="h6" className={classNames(errorBlock, errorFont)}>
        Please Verify Details
        <br />
        In order to submit your request we need you to remedy the errors below,
        select the error to jump to section:{' '}
      </Typography>
      <ul className={classes.errorList}>
        {errorMsgs.map(({ field, message }) => (
          <li key={`${field}_${message}`} className={errorBlock}>
            <Button
              variant="text"
              size="small"
              onClick={() => onClickError(field)}
              classes={{
                label: errorFont,
              }}
              className={classes.error}
            >
              {message}
            </Button>
          </li>
        ))}
      </ul>
    </Grid>
  );
};
