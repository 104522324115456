import React from 'react';
import { useIntl } from 'react-intl';

import { useScreenIsSmallerThan } from '@Dogtainers/dgt-blocks/src/hooks/useBreakpoints';
import { AppBar, Container, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import menu from '../../../../content/settings/menu.json';
import { useAnalytics } from '../../../hooks/useAnalytics';
import { defaultLocale } from '../../../utils/language';
import { DesktopMenu } from './menu/Desktop';
import { MobileMenu } from './menu/Mobile';

export type MenuItem = {
  id: string;
  label: string;
  link: string;
  menuItems: MenuItem[];
  localeDomains?: string[];
};

type StyleProps = {
  headerTextColour: string;
};

const useStyles = makeStyles((theme) => ({
  root: {
    color: (props: StyleProps) => props.headerTextColour,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 12,
      paddingRight: 12,
    },
  },
}));

export const Nav: React.FC<{ pageId: string; isPageTop?: boolean }> = ({
  pageId,
  isPageTop,
}) => {
  const headerTextColour =
    !!isPageTop && pageId === 'home' ? '#ffffff' : '#000000';
  const styleProps: StyleProps = { headerTextColour };
  const sendAnalyticsEvent = useAnalytics();
  const classes = useStyles(styleProps);

  const isLargeTablet = useScreenIsSmallerThan(1082);
  const intl = useIntl();
  const getSiteMessage = (id: string, fallback: string) =>
    Object.keys(intl.messages).includes(id)
      ? String(intl.messages[id])
      : fallback;

  function filterMenuLocale(menuItems: MenuItem[]): MenuItem[] {
    return menuItems
      .map((childItem) => {
        if (childItem?.menuItems.length) {
          childItem.menuItems = filterMenuLocale(childItem.menuItems);
        }
        return childItem;
      })
      .filter(({ localeDomains }) => {
        if (!localeDomains?.length) {
          return true;
        }
        return localeDomains.includes(defaultLocale);
      });
  }

  const menuItems = filterMenuLocale(menu.menuItems);

  /**
   * Converts translated phone into "tel:XXX" format
   */
  function getPhoneTelUri(): string {
    let ph = getSiteMessage('navPhone', '');
    ph = ph.replace(/ /g, ''); // strip whitespace
    return `tel:${ph}`;
  }

  function triggerCall(): void {
    sendAnalyticsEvent({ event: 'clickToCall' });
    window.open(getPhoneTelUri(), '_SELF');
  }

  return (
    <Container className={classes.root}>
      <AppBar position="sticky" color="transparent">
        {isLargeTablet ? (
          <MobileMenu
            headerTextColour={headerTextColour}
            onCall={triggerCall}
            menuItems={menuItems}
          />
        ) : (
          <DesktopMenu
            headerTextColour={headerTextColour}
            onCall={triggerCall}
            menuItems={menuItems}
          />
        )}
      </AppBar>
    </Container>
  );
};
