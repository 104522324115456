import React from 'react';

import { DatePicker, Input } from '@Dogtainers/dgt-blocks/src/components/form';
import { Grid } from '@material-ui/core';

import { MediaTypeProps } from '../types';

const Television: React.FC<MediaTypeProps> = ({
  handleChange,
  values,
  errors: { mediaEnquiry: mediaEnquiryError },
}) => {
  const { television } = values || {};
  const { television: televisionErrors } = mediaEnquiryError || {};

  return (
    <>
      <Grid item>
        <Input
          label="Company website / Streaming platform link"
          name="mediaEnquiry.television.televisionCompanyWebsite"
          placeholder="e.g. tv.com.au"
          onChange={handleChange}
          value={television?.televisionCompanyWebsite}
          error={televisionErrors?.televisionCompanyWebsite}
        />
      </Grid>
      <Grid item>
        <DatePicker
          label="Recording date"
          name="mediaEnquiry.television.televisionRecordingDate"
          onChange={handleChange}
          value={television?.televisionRecordingDate}
          error={televisionErrors?.televisionRecordingDate}
        />
      </Grid>
      <Grid item>
        <Input
          label="Television opportunity topic"
          name="mediaEnquiry.television.televisionTopic"
          placeholder="e.g. Pet Travel"
          onChange={handleChange}
          value={television?.televisionTopic}
          error={televisionErrors?.televisionTopic}
        />
      </Grid>
      <Grid item>
        <Input
          multiline
          rows={6}
          label="Television opportunity brief"
          placeholder="Give us a quick rundown of the project and any info you think we may need."
          name="mediaEnquiry.television.televisionRadioBrief"
          onChange={handleChange}
          value={television?.televisionRadioBrief}
          error={televisionErrors?.televisionRadioBrief}
        />
      </Grid>
    </>
  );
};

export default Television;
