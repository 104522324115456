import { navigate } from 'gatsby';
import React, { useContext, useState } from 'react';

import {
  IconCheck,
  IconCountry,
  IconGlobe,
} from '@Dogtainers/dgt-blocks/src/components/icon';
import {
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import localeSettings from '../../../../../content/settings/i18n.json';
import { LoadingContext } from '../../../../layouts/site.layout';
import { Locale } from '../../../../typings/i18n.types';
import {
  getCurrentLocale,
  getExternalDomain,
  getLocaleCountry,
  getLocaleCountryCode,
  localiseUrl,
  saveUserLocale,
} from '../../../../utils/language';

const { locales } = localeSettings;

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: '1rem',
  },
}));

export const LocaleMenuMobile: React.FC = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const { setOverlay } = useContext(LoadingContext);
  const classes = useStyles();
  const currentLocale = getCurrentLocale();

  function setLocale(newLocale: Locale) {
    saveUserLocale(newLocale);
    setMenuOpen(false);
    const externalDomain = getExternalDomain(newLocale);
    if (externalDomain) {
      setOverlay(`Taking you to ${externalDomain} ...`);
    }
    navigate(localiseUrl(undefined, newLocale));
  }

  return (
    <List component="div" disablePadding>
      <ListItem button onClick={() => setMenuOpen((c) => !c)}>
        <ListItemText>
          <Grid container spacing={2}>
            <Grid item style={{ flexGrow: 0 }}>
              <IconGlobe />
            </Grid>
            <Grid item style={{ flexGrow: 0, minWidth: 35 }}>
              <Typography style={{ textTransform: 'uppercase' }}>
                {getLocaleCountryCode(currentLocale as Locale)}
              </Typography>
            </Grid>
            <Grid
              item
              style={{
                flexGrow: 0,
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              •
            </Grid>
            <Grid item style={{ flexGrow: 0 }}>
              <Typography style={{ textTransform: 'uppercase' }}>
                {getLocaleCountry(currentLocale as Locale)}
              </Typography>
            </Grid>
          </Grid>
        </ListItemText>
        {isMenuOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={isMenuOpen} timeout="auto" unmountOnExit>
        <List className={classes.root}>
          {locales.map((locale) => (
            <ListItem
              button
              key={locale}
              onClick={() => setLocale(locale as Locale)}
            >
              <Grid container spacing={2}>
                <Grid item style={{ flexGrow: 0 }}>
                  <IconCountry locale={locale as Locale} />
                </Grid>
                <Grid item style={{ flexGrow: 0, minWidth: 35 }}>
                  <Typography style={{ textTransform: 'uppercase' }}>
                    {getLocaleCountryCode(locale as Locale)}
                  </Typography>
                </Grid>
                <Grid
                  item
                  style={{
                    flexGrow: 0,
                    paddingLeft: 0,
                    paddingRight: 0,
                  }}
                >
                  •
                </Grid>
                <Grid item style={{ flexGrow: 0 }}>
                  <Typography style={{ textTransform: 'uppercase' }}>
                    {getLocaleCountry(locale as Locale)}
                  </Typography>
                </Grid>
                <Grid item style={{ flexGrow: 1 }}>
                  {locale === currentLocale && <IconCheck />}
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </List>
  );
};
